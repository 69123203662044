import { NgModule } from '@angular/core';
import { FooterComponent } from '../../layouts/panelLayout/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    RouterModule,
    TranslateModule.forChild({}),
    CommonModule
  ],
  exports: [
    TranslateModule,
    FooterComponent,
    RouterModule
  ]
})
export class AppSharedModule { }
